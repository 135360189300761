<template>
  <div class="all-circle-container">
    <div class="side">
      <SideBox
        title="分类"
        :sideList="sideList"
        @requestClick="requestClick"
        @requestAll="handleAll"
      ></SideBox>
    </div>
    <div class="container">
      <AllCard :cardList="cardList"></AllCard>
    </div>
  </div>
</template>

<script>
import SideBox from "@/components/pager/SideBox.vue";
import AllCard from "../components/AllCard.vue";
export default {
  components: {
    AllCard,
    SideBox,
  },
  data() {
    return {
      sideList: [],
      cardList: [],
    };
  },
  created() {
    this.circleList();
    this.getCircleType();
  },
  methods: {
    async circleList(data) {
      const resp = await this.$apis.circleServe.circleList(data);
      this.cardList = resp.data;
    },
    async getCircleType() {
      const resp = await this.$apis.circleServe.getCircleType();
      this.sideList = resp.data;
    },
    requestClick(item) {
      this.circleList({
        type: item.id,
      });
    },
    handleAll() {
      // 请求全部
      this.circleList();
    },
  },
};
</script>

<style lang="scss" scoped>
.all-circle-container {
  width: 1439px;
  display: flex;
  margin: 69px auto;
  .side {
    width: 255px;
    margin-right: 40px;
  }
  .circle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 29px;
    margin-bottom: 15px;
    .new {
      font-size: 18px;
      color: #333333;
    }
    .all {
      font-size: 16px;
      color: #00a4ff;
      cursor: pointer;
    }
  }
  .container {
    width: 1040px;
    margin-right: 40px;
  }
}
</style>
